<template>

  <div v-if="$store.getters.viewBox != 'list'">
    <div class="changeTime" >
      <a href="#" v-on:click.prevent="open = !open" v-bind:class="{clicked: open}">+</a>
      <div>{{ $store.getters.l.findDate }}</div>
    </div>

    <transition name="getDates">
      <div v-if="open" class="timePicker">

        <div v-if="notToday()">
          <a href="#" v-on:click.prevent="changeToday()">{{ $store.getters.l.today }}</a>
        </div>

        <select v-model="date.day">
          <option v-for="i in days" v-bind:key="i[0]"  v-bind:value="(i[0] + 1)" v-html="i[1]"/>
        </select>

        <select v-model="date.month">
          <option v-for="i in months" v-bind:key="i" v-show="i" v-bind:value="i" v-html="monthNames[i]"/>
        </select>

        <select v-model="date.year">
          <option v-for="i in year" v-bind:key="i+'plus'" v-bind:value="i" v-html="i"/>
        </select>

        <a v-if="date.day && date.month && date.year" href="#" v-on:click.prevent="changeDate()">{{ $store.getters.l.change }}</a>



      </div>
    </transition>
  </div>
</template>

<script>

export default{

  data(){

    var months = this.$store.getters.l.monthsNames;

    return{
      open: false,
      monthNames: months,

      date: this.$store.getters.searchDate,
      days: [],
      months: [],
      year: []
    }
  },

  mounted () {
    this.getYear();
    this.getWeekday();
    this.getMonths();
  },
  watch: {
    'date.month' () { this.getWeekday() },
    'date.year' () { this.getWeekday(); this.getMonths(); }
  },
  methods:{
    getWeekday () {

      this.days = []
      var end = this.$generateDate( this.date.year + '-' + ( this.date.month + 1 ) + '-' + '0' )
      var checkDate = new Date()
      checkDate.setHours(0)
      checkDate.setMinutes(0)
      checkDate.setSeconds(0)
      checkDate.setMilliseconds(0)

      for(var i=1; i<=(end.getDate() * 1); i++){
        var day = this.$generateDate( this.date.year + '-' + ( this.date.month ) + '-' + i, 0, 0, 0 )

        var weekday = day.getDay() * 1


        if( weekday === 0 ){
          weekday = 7;
        }

        weekday = weekday - 1;

        if (day.getTime() >= checkDate.getTime() && typeof this.$store.getters.data.include.weekdays === "object" && typeof this.$store.getters.data.include.weekdays[weekday] === "object" && this.$store.getters.data.include.weekdays[weekday].length) {

          if (this.$checkDate(this.date.year + '-' + this.$getNullsBefore( this.date.month ) + '-' + this.$getNullsBefore(i))) {
            this.days.push([(i-1), this.$store.getters.l.weekdays[weekday] + ', ' + this.$getNullsBefore(day.getDate()) + '.'])
          }
        }
      }

    },

    getYear () {
      this.year = []
      var start = new Date().getFullYear();

      for (var i=0; i<=1; i++) {
        if (this.$checkDate((start * 1) + i)) {
          this.year.push((start * 1) + i)
        }
      }
    },

    getMonths () {

      this.months = []
      var now = this.$generateDate( this.date.year + '-' + ( new Date().getMonth() + 1 ) + '-' + '1' )
      var start = (now.getMonth() + 1)
      if ( (now.getFullYear() * 1) > (new Date().getFullYear() * 1) ) {
        start = 1
      }

      for(var i = start; i<=12; i++){

        if (this.$checkDate(this.date.year + '-' + this.$getNullsBefore( i ) )) {
          this.months.push(i)
        }
      }

    },
    notToday(){

      var date = this.$store.getters.date;
      date = date.split(" ");
      date = date[0].split("-");

      if( new Date(date[0], date[1]-1, date[2], 0,0,0,0,0).getTime() == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0,0).getTime() )
        return false;

      return true;
    },

    changeToday(){

      this.$store.commit("changeDate", new Date());

    },

    changeDate(){

      this.$store.commit("changeDate", new Date(this.date.year, this.date.month-1, this.date.day, 0,0,0,0,0));

    }
  }

}

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/menu.sass'
  @import '@/style/changeTime.sass'

</style>
