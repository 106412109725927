<template>

  <div :key="$store.getters.save.length" ref="app">
    <success v-if="$store.getters.save" :newBooking = "function () { newBooking() }"/>
    <div v-else-if="$store.getters.data.type">
      <calendarSwitch />
      <component v-bind:is="$store.getters.data.type"   />
    </div>
    <span v-html="$store.getters.l.brand" :style="$getStyling(['color'], 'accent')"></span>
  </div>

</template>

<script>

import datepicker from '@/components/startBtn'
import eventpicker from '@/components/eventpicker'
import success from '@/components/success'
import calendarSwitch from '@/components/calendarSwitch'

export default {
  name: 'app',
  props: {
    getReload: Function
  },
  components: { datepicker, eventpicker, success, calendarSwitch },

  mounted() {
    if (typeof this.$store.getters.data === 'object' && typeof this.$store.getters.data.lang === 'string'){
      this.$store.commit('setLanguage', this.$store.getters.data.lang);
    }
    this.$cleanDates()
  },
  methods: {
    newBooking () {
      this.getReload()
    }
  }

}
</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'

  span
    font-size: 9pt
    color: $active
    display: block
    text-align: right
    margin-top: 8px
    font-family: $font

</style>
