<template>

  <div :class="{flixFocus: focused}">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <ul class="select">
    <li v-for="(term) in ['yes']" :class="{active: active[term]}" :key="term" @click="getClick(term)">
      {{ $store.getters.l[term] }}
    </li>
    </ul>
    <div v-if="data.description">
      <a href="#" class="viewTerms" v-html="$store.getters.l.terms" @click.prevent="open = !open"></a>
      <div class="viewTerms" v-if="open" v-html="data.description"></div>
    </div>
  </div>

</template>

<script>

import getLabel from '@/components/form/parts/label';

export default{

  props: {data: Object, callback: Function},
  components: {getLabel},

  data(){

    return{
      focused: false,
      input: this.data.value,
      open: false,
      active: {"yes": false}
    }
  },
  methods: {
    getClick (term) {
      this.active = {"no": false, "yes": false}
      this.active[term] = true

      this.callback(this.$store.getters.l[term]);
    }
  }
}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/label.sass'
  @import '@/style/form/select.sass'
  @import '@/style/form/agb.sass'
</style>
