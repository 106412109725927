<template>
  <div>

    <div class="menu">
      <a href="#" v-html="'<'" v-on:click.prevent="setDay(-1)"></a>
      <a href="#" v-on:click.prevent="getBack()" :title="$store.getters.l.backToHourView" v-html="getWeekday()+', '+currDay+'<br />'+getTimeRange()+' '+oClock"></a>
      <a href="#" v-html="'>'" v-on:click.prevent="setDay(1)"></a>

      <a href="#" v-on:click.prevent="getBack()" class="info">{{ $store.getters.l.backToHourView }}</a>
    </div>


    <serviceInfo />

    <table cellspacing="0" cellpadding="0" v-if="checkedDates && Object.keys(checkedDates).length">

      <transition name="getDates">
        <tbody :key="currDay + day.getHours()">
        <tr v-for="i in checkedDates" :key="'row'+i+1" >
          <td>
            <div
            :title="getWeekday()+', '+currDay+', '+getDate(i)+' '+oClock"
            v-on:click.prevent="getClick(i)"
            @mouseenter="removeStyle" @mouseleave="setStyle"
            :style="$getStyling(['border-bottom-color'], 'accent')"
            :class="{
              active: true,
              current: ''

              }" >

              {{ getDate(i) }} <span>{{ oClock }}</span> <i v-if="$store.getters.data.showAppointments" :style="$getStyling(['color'], 'accent')">– {{ getMaxApplicants(i) }} –</i>

            </div>
          </td>
        </tr>
      </tbody>
    </transition>

    </table>
    <bookingNotPossible v-else />
    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import serviceInfo from '@/components/functions/serviceInfo';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{

    props: {date: String},
    components: {changeTime, debugTime, serviceInfo, bookingNotPossible},

    data(){
      var date = this.$generateDate(this.$store.getters.date)
      date = this.setMinutes(date)
      return{
        oClock: this.$store.getters.oClock,
        weekdays: this.$store.getters.weekdays,
        day: date,
        currDay: this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear(),
        checkedDates: false,
        interval: this.$getNextDate(date, this.$store.getters.data)
      }
    },
    mounted() {
      this.getCheckedDates()
    },
    methods:{
      removeStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (color) {
          event.target.style['background-color'] = color.color
          event.target.getElementsByTagName('i')[0].style.color = '#ffffff'
        }
      },
      setStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (color) {
          event.target.style['background-color'] = 'inherit'
          event.target.getElementsByTagName('i')[0].style.color = color.color
        }
      },
      setMinutes (date) {
        var interval = this.$getNextDate(date, this.$store.getters.data);
        var weekday = date.getDay();
        weekday--;
        if(weekday < 0){
          weekday = 6;
        }

        if (typeof this.$store.getters.data.include.weekdays[weekday] === 'undefined' || typeof this.$store.getters.data.include.weekdays[weekday][0] === 'undefined'){
          return date
        }
        var start = this.$store.getters.data.include.weekdays[weekday][0].split(":")
        if((start[0] * 1) === (date.getHours() * 1) || (interval * 1) === 60 ){
          date.setMinutes(start[1] * 1)
        } else {
          date.setMinutes(0)
        }
        return date;
      },
      getTimeRange () {
        var endHour = new Date(this.day.valueOf())
        endHour.setHours(endHour.getHours() + 1)

        return this.$getNullsBefore(this.day.getHours()) + ':xx '
      },
      getCheckedDates(){
        this.checkedDates = {};
        var j = 0
        for(var i=0; i<=3; i++) {
          if(j >= 60){
            continue;
          }
          var check = this.isActive(i)
          if(check){
            this.checkedDates[i] = i
          }
          j += this.interval
        }

      },
      isActive(i){
        var begin = new Date(this.day.valueOf())
        begin.setMinutes(this.day.getMinutes() + (i * 1 * this.interval))

        if (begin.getHours() * 1 > this.day.getHours() * 1){
          return false;
        }

        var end = this.$getEndDate(begin);
        for(var j = 0; j<1000; j++){

          var check = new Date(this.day.valueOf())
          check.setMinutes(this.day.getMinutes() + (i * 1 * this.interval + ( j * 5)))

          if(check.getTime() > end.getTime()){
            break;
          }

          if( this.$checkDate(check.getFullYear()+'-'+this.$getNullsBefore((check.getMonth()+1))+'-'+this.$getNullsBefore(check.getDate())+" "+this.$getNullsBefore(check.getHours())+":"+this.$getNullsBefore(check.getMinutes())) == false){
            return false
          }


        }
        return true
      },

      getBack(){

        this.$store.commit('setDay', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(),0, 0 ));
        this.$store.commit('setView', -1)

      },

      getClick(i){
        var day = new Date(this.day.getTime());
        day.setMinutes( day.getMinutes() + (i * this.interval) );
        this.$store.commit('setHour', day);
        this.$store.commit('setView', 1);

      },
      getDate(i){

        var newDay = new Date(this.day.valueOf());
        newDay.setMinutes(this.day.getMinutes() * 1 + (i * this.interval) );
        var end = this.$getEndDate(newDay);

        var r = "";
        r += this.$getNullsBefore(newDay.getHours())+":"+this.$getNullsBefore(newDay.getMinutes());

        if(end.getTime() == newDay.getTime())
          return r;

        r += ' - ';
        r += this.$getNullsBefore(end.getHours())+":"+this.$getNullsBefore(end.getMinutes() );

        return r;

      },

      getMaxApplicants(i){
        var newDay = new Date(this.day.getTime());
        newDay.setMinutes(this.day.getMinutes() * 1 + (i * this.interval) );
        var end = this.$getEndDate(newDay);
        var maxApplicants = this.$store.getters.approvedApplicants

        if (newDay.getTime() === end.getTime()) {
          maxApplicants = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]
        } else {
          var check = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' +  this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())][2]
          var ma = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]
            for (var j = 0; j <= ((end.getTime() - newDay.getTime()) / (60 * 1000) ); j+=5) {
              var nd = new Date(newDay.getTime())
            if(nd.getTime() > end.getTime()) {
              continue;
            }
            nd.setMinutes(nd.getMinutes() * 1 + j);
            var check2 = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
            if (check < check2[0]) {
              check = check2[0]
              ma = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
            }
          }
          maxApplicants = ma
        }

        return this.$store.getters.l.availibleApplicants.split('{count}').join((maxApplicants[1] - maxApplicants[0]));
      },

      getWeekday(){

        var weekday = this.day.getDay();

        if(weekday == 0)
          weekday = 7;

        return this.weekdays[weekday -1];
      },
      setDay(add){

        var date = this.day;
        date.setHours( date.getHours() + add );
        date = this.setMinutes(date);

        this.day = date;

        this.currDay = this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear()
        this.getCheckedDates()
      }
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    td > div

      > span
        font-size: 8pt
        display: block
      > i
        font-size: 9pt
        display: block
        color: $active
        font-variant: normal
        font-style: normal
      &:hover
        > i
          color: white

  </style>
