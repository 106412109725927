<template>
  <div>

    <div class="menu">
      <a href="#" v-html="'<'" v-on:click.prevent="setMonth(-1)" ></a>
      <a href="#" v-html="months[currMonth]+' '+currYear" :title="$store.getters.l.backToMonthView" v-on:click.prevent="$store.commit('setView', -1)"></a>
      <a href="#" v-html="'>'" v-on:click.prevent="setMonth(1)" v-on:click.right="setMonth(1)"></a>
      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" class="info">{{ $store.getters.l.backToMonthView }}</a>
    </div>


      <transition name="getDates">
        <div :key="currMonth+''+currYear+new Date().getTime()">
          <div class="dates" v-if="data.length">
              <a v-for="(day, index) in data" :key="'week'+index"
                :style="getStyling()"
                @mouseenter="getHover"
                @mouseleave="removeHover"
                v-on:click="getClick(day.day[0])"
                :title="$getNullsBefore(day.day[0].getDate())+'.'+$getNullsBefore((day.day[0].getMonth()+1))+'.'+day.day[0].getFullYear()"
                :class="{
                  active: true,
                  currMonth: day.day[1],
                  current: day.day[0].getFullYear()+''+day.day[0].getMonth()+''+day.day[0].getDate() == new Date().getFullYear()+''+new Date().getMonth()+''+new Date().getDate()
                  }"
                >
                {{ $getNullsBefore(day.day[0].getDate()) }}.{{ $getNullsBefore(currMonth) }}.{{ currYear }} <span>{{ day.weekday }}</span>
              </a>
            </div>
            <bookingNotPossible v-else/>
        </div>
      </transition>


    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{
    props: {date: String},
    components: {changeTime, debugTime, bookingNotPossible},

    data(){

      var date = this.date.split("-");

      return{
        currMonth: date[1] * 1,
        currYear: date[0] * 1,
        weekdays: this.$store.getters.l.weekdays,
        months: this.$store.getters.l.monthsNames,
        data: [],
        active_count: 0
      }
    },
    watch: {
      currMonth () { this.data = {}; this.getData() }
    },
    computed:{

      getDaysPerWeek(){
        var days = this.getDays();

        var i = 0;
        var j = 0;
        var r = [];

        Object.keys(days).forEach(function(key){

          j++;

          if(typeof r[i] == "undefined")
            r[i] = {};

          r[i][key] = days[key];

          if( j % 7 == 0 ){
            i++;
          }
        });

        return r;
      }
    },

    methods:{
      getData () {
        this.data = []
        this.active_count = 0
        var weekdays = this.$store.getters.l.weekdays
        if(typeof Object.keys(this.getDaysPerWeek) === "undefined"){
          return false
        }
        Object.keys(this.getDaysPerWeek).forEach(function (index) {
          var week = this.getDaysPerWeek[index]
          Object.keys(week).forEach(function (index2) {
            var day = week[index2]
            var weekday = day[0].getDay()

            weekday--;
            if(weekday === -1) {
              weekday = 6
            }
            if (this.isActive(index2)) {
              this.data.push({day: day, active: true, weekday: weekdays[weekday]})
              this.active_count += 1
            }

          }.bind(this))
        }.bind(this))

      },
      getStyling () {
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      getClick(day){

        this.$store.commit('setDay', day);
        this.$store.commit('setView', 1);

      },
      isActive(index2){
        return this.$checkDate(index2);
      },
      setMonth(add){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(((this.currMonth * 1) + (add * 1)));


        this.currMonth = daysOfMonth.getMonth();
        this.currYear = daysOfMonth.getFullYear();

        if(!this.currMonth){

          this.currMonth = 12;
          this.currYear--;

        }

      },

      getDays(){

        var date = {};

        date = Object.assign(date, this.getDatesCurrentMonth());

        return date;



      },


      getDatesNextMonth(){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(this.currMonth);
        daysOfMonth.setDate(0);

        var weekday = daysOfMonth.getDay();

        if(weekday == 0)
          weekday = 7;

        var r = {};

        var j = 1;
        for( var i = weekday; i< 7; i++ ){

          var currDate = new Date();
          currDate.setDate(1);
          currDate.setYear(this.currYear);
          currDate.setMonth(this.currMonth);
          currDate.setDate(j);
          j++;

          r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, false];
        }

        return r;

      },

      getDatesCurrentMonth(){

        var daysOfMonth = new Date();
        daysOfMonth.setDate(1);
        daysOfMonth.setYear(this.currYear);
        daysOfMonth.setMonth(this.currMonth);
        daysOfMonth.setDate(0);

        var r = {};

        for(var i = 1; i<=daysOfMonth.getDate(); i++){
          var currDate = new Date();
          currDate.setDate(1);
          currDate.setYear(this.currYear);
          currDate.setMonth(this.currMonth-1);
          currDate.setDate(i * 1);

          r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, true];
        }

        return r;
      },

      getDatesLastMonth(){

        var firstDayOfMonth = new Date();
        firstDayOfMonth.setDate(1);
        firstDayOfMonth.setYear(this.currYear);
        firstDayOfMonth.setMonth(this.currMonth-1);

        var weekday = firstDayOfMonth.getDay();

        if(weekday == 0)
          weekday = 7;

        var r = {};

        for(var i = ( weekday * -1) +2; i<=0; i++){

            var currDate = new Date();
            currDate.setDate(1);
            currDate.setYear(this.currYear);
            currDate.setMonth(this.currMonth -1);
            currDate.setDate((i * 1));

            r[currDate.getFullYear()+"-"+this.$getNullsBefore(currDate.getMonth()+1)+"-"+this.$getNullsBefore(currDate.getDate())] = [currDate, false];

        }

        return r;
      }
    },


    mounted() {

      this.getDays();
      this.getData();
    }
  }

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/menu.sass'
  @import '@/style/month.sass'

  td > div
    &.active
      display: block

</style>
