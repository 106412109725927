var checkLastDayBooking = function(atts){

  this.error = false;
  this.debug = {text: 'debugLastDayBooking', status: "no", error: false}

  this.check = function(){

     var endDate = this.getEndDate();

     if(!endDate){
       window.debugBookingCalendar.push(this.debug)
       return false;
     }

    var endHour = this.getEndHour();

    // if( endDate.getTime() > endHour.getTime() ) // Bugfix 10.04.2020 ggfls. zurücksetzen
    if( this.date.getTime() > endHour.getTime() ){
      this.debug.status = "yes";
      this.debug.error = true;
      this.error = true;
    }
    window.debugBookingCalendar.push(this.debug)
  }

  this.getEndHour = function(){

    var Hi = atts.include.weekdays[ this.weekday ][1];

    Hi = Hi.split(":");

    var endHour = new Date( this.date.valueOf() );
    endHour.setHours( Hi[0] );
    endHour.setMinutes( Hi[1] );

    return endHour;
  }

  this.getEndDate = function(){

    this.weekday = this.date.getDay();

    if( this.weekday == 0 )
      this.weekday = 7;

    this.weekday = this.weekday - 1;

    if( typeof atts.include.weekdays == "undefined" || typeof atts.include.weekdays[this.weekday] == "undefined" || typeof atts.include.weekdays[this.weekday][2] !== "number" )
      return false;

    var endTime = atts.include.weekdays[ this.weekday ][2];

    var end = new Date( this.date.valueOf() );
    end.setMinutes( end.getMinutes() * 1 + endTime );

    return end;
  }

};

export default checkLastDayBooking;
