<template>
  <div class="bookingNotPossible">
    {{ $store.getters.l.bookingNotPossible }}
  </div>
</template>
<script>
export default {
  components: {},
  props: {}
}
</script>
<style lang="sass" scoped>

  @import '@/style/variables.sass'

  .bookingNotPossible
    text-align: center
    font-family: $font
    font-weight: bold
    font-size: 14pt
    color: $error

</style>
