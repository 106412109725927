<template>
  <div>

    <div class="menu">
      <a href="#" v-html="'<'" v-on:click.prevent="setDay(-1)"></a>
      <a href="#" v-on:click.prevent="setView()" :title="$store.getters.l.backToDayView" v-html="getWeekday()+', '+currDay"></a>
      <a href="#" v-html="'>'" v-on:click.prevent="setDay(1)"></a>

      <a href="#" v-on:click.prevent="setView()" class="info">{{ $store.getters.l.backToDayView }}</a>
    </div>

    <serviceInfo />

    <transition name="getDates">
      <div :key="currDay">
        <div v-if="data.length" class="dates">
          <a
          class="active"
          @mouseenter="removeStyle"
          @mouseleave="setStyle"
          v-on:click.prevent="getClick(d.i * 1, d.j * 1)"
          href="#"
          v-for="(d, index) in data"
          :key="index"
          :title="d.title"
          :style="getStyling(d.i * 1, d.j * 1)">
            {{ d.time }} <span>{{ oClock }}</span>
          </a>
        </div>
        <bookingNotPossible v-else />
      </div>
    </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import serviceInfo from '@/components/functions/serviceInfo';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{

    props: {date: String, callback: Function},
    components: {changeTime, debugTime, serviceInfo, bookingNotPossible},

    data(){
      var date = this.$generateDate(this.$store.getters.date)

      return{
        rows: Object.keys([1, 2, 3, 4, 5, 6]),
        columns: Object.keys([1, 2, 3, 4]),
        oClock: this.$store.getters.l.oClock,
        weekdays: this.$store.getters.weekdays,
        day: date,
        currDay: this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear(),
        interval: this.$getNextDate(date, this.$store.getters.data),
        data: []
      }
    },

    watch: {
      currDay: function () { this.data = []; this.getData() }
    },

    methods:{
      getData () {
        this.rows.forEach(function (i) {
          this.columns.forEach(function (j) {
            if ((i * 1 + i * 1 + i * 1 + i * 1 + j * 1) < 24 && this.isActive(i * 1, j * 1)) {
              this.data.push({
                title: this.getWeekday()+', '+this.currDay+', '+this.$getNullsBefore((i * 1 + i * 1 + i * 1 + i * 1 + j * 1))+'-'+this.$getNullsBefore((i * 1 + i * 1 + i * 1 + i * 1 + j * 1 + 1 ))+' '+this.oClock,
                time: this.$getNullsBefore((i * 1 + i * 1 + i * 1 + i * 1 + j * 1 )),
                i: i,
                j: j
              })
            }
          }.bind(this))
        }.bind(this))
      },
      removeStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (event.target.classList.contains('active')) {
          if (color) {
            event.target.style['background-color'] = color.color
          }
        }
      },
      setStyle (event) {
        var color = this.$getStyling(['color'], 'accent')
        if (event.target.classList.contains('active')) {
          if (color) {
            event.target.style['background-color'] = 'inherit'
          }
        }
      },
      getStyling () {
        var style = this.$getStyling(['border-bottom-color'], 'accent')

        return style
      },
      setMinutes (date) {
        var weekday = date.getDay();
        weekday--;
        if(weekday < 0){
          weekday = 6;
        }

        if (typeof this.$store.getters.data.include.weekdays[weekday] === 'undefined' || typeof this.$store.getters.data.include.weekdays[weekday][0] === 'undefined'){
          return date
        }
        var start = this.$store.getters.data.include.weekdays[weekday][0].split(":")
        if((start[0] * 1) === (date.getHours() * 1) || (this.interval * 1) === 60 ){
          date.setMinutes(start[1] * 1)
        } else {
          date.setMinutes(0)
        }
        return date;
      },
      setView(){

        this.$store.commit("setService", false);

        if(typeof this.callback == "function")
          this.callback(false);
        else
          this.$store.commit('setView', -1);


      },
      getClick(i,j){

        if(!this.isActive(i * 1, j * 1)){
          this.$store.commit('setErrorKey', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(), i * 1 + i * 1 + i * 1 + i * 1 + j * 1 , 0 ));
          return false;
        }

        this.$store.commit('setHour', new Date( this.day.getFullYear(), (this.day.getMonth()), this.day.getDate(), i * 1 + i * 1 + i * 1 + i * 1 + j * 1 , 0 ));
        this.$store.commit('setView', 1);

      },

      isActive(i,j){
        var r = false

        for(var a = 0; a < 60; a += this.interval){
          if(r === true){
            continue;
          }
          if (this.checkDate(a, (i * 1 + i * 1 + i * 1 + i * 1 + j * 1 ))) {
            r = true;
          }
        }
        return r;
      },

      checkDate(minutes, hours){

        var b = new Date( this.day.valueOf() );
        b.setHours(hours);
        b = this.setMinutes(b);
        b.setMinutes(b.getMinutes() * 1 + minutes);

        var e = new Date( b.valueOf() );
        e = this.$getEndDate(e);

        if(b.getDate() != e.getDate()){
          return false;
        }

        var check_begin = this.$checkDate( b.getFullYear()+'-'+this.$getNullsBefore((b.getMonth()+1))+'-'+this.$getNullsBefore(b.getDate())+' '+this.$getNullsBefore(b.getHours())+':'+this.$getNullsBefore(b.getMinutes()) );
        var check_end = this.$checkDate( e.getFullYear()+'-'+this.$getNullsBefore((e.getMonth()+1))+'-'+this.$getNullsBefore(e.getDate())+' '+this.$getNullsBefore(e.getHours())+':'+this.$getNullsBefore(e.getMinutes()), true);

        if(check_begin && check_end){
          return this.checkTimeBetween (b, e);
        }
        return false;
      },
      checkTimeBetween (b, e) {
        var r = true
        var begin = new Date(b.getTime())
        for (var i = 0; i<=1000; i+=1) {

          begin.setMinutes( begin.getMinutes() + 15 )

          if(r == false || e.getTime() - begin.getTime() <= 0) {
            return r
          }

          if (!this.$checkDate( begin.getFullYear()+'-'+this.$getNullsBefore((begin.getMonth()+1))+'-'+this.$getNullsBefore(begin.getDate())+' '+this.$getNullsBefore(begin.getHours())+':'+this.$getNullsBefore(begin.getMinutes()) )) {
            r = false
          }
        }
        return r
      },
      getWeekday(){


        var weekday = this.day.getDay();

        if(weekday == 0)
          weekday = 7;

        return this.weekdays[weekday -1];
      },
      setDay(add){
        var date = this.day;
        date.setDate( date.getDate() + add );

        this.day = date;

        this.currDay = this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear()

      }
    },
    mounted () {
      this.getData()
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/hours.sass'
    @import '@/style/menu.sass'

  </style>
