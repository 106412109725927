<template>
  <div>

    <div class="menu">
      <a href="#" v-html="'<'" v-on:click.prevent="setYear(-1)"></a>
      <a href="#" v-html="currYear" :title="$store.getters.l.backToYearView" v-on:click.prevent="$store.commit('setView', -1)"></a>
      <a href="#" v-html="'>'" v-on:click.prevent="setYear(1)"></a>
      <a href="#" v-on:click.prevent="$store.commit('setView', -1)" class="info">{{ $store.getters.l.backToYearView }}</a>
    </div>

    <transition name="getDates">
      <div class="dates" :key="currMonth+''+currYear" v-if="active_count">
        <a v-for="(date, index) in data" :key="index"
        href="#"
        @mouseenter="getHover"
        @mouseleave="removeHover"
        class="active"
        v-on:click.prevent="setClick(date.i * 1, date.j * 1)"
        :title="date.title"
        :class="{
          current: date.current
          }"
        :style="getStyling()"
        >{{ date.month }}</a>
      </div>
      <bookingNotPossible v-else />
    </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{

    props: {date: String},
    components:{changeTime, debugTime, bookingNotPossible},

    data(){

      var date = this.date.split("-");

      return{
        rows: Object.keys([1, 2, 3, 4]),
        columns: Object.keys([1, 2, 3]),
        currMonth: date[1] * 1,
        currYear: date[0] * 1,
        months: this.$store.getters.l.monthsNames,
        data: {},
        active_count: 0
      }
    },
    watch: {
      currYear () { this.data = {}; this.getData() }
    },
    methods:{
      getData () {
        this.active_count = 0
        this.data = []
        this.rows.forEach(function (i) {
          this.columns.forEach(function (j) {
            if (this.isActive(i * 1, j * 1)) {
              this.data.push({
                current: this.currYear+''+(i * 1 + i * 1 + i * 1 + j * 1 + 1) == new Date().getFullYear()+''+(new Date().getMonth() * 1+1),
                month: this.months[ i * 1 + i * 1 + i * 1 + j * 1 + 1 ],
                title: this.months[ i * 1 + i * 1 + i * 1 + j * 1 + 1 ]+' '+this.currYear,
                i: i,
                j: j
              })
              this.active_count += 1
            }
          }.bind(this))
        }.bind(this))
      },
      getStyling () {
        //return {'border-color': 'red'}
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      setClick(i,j){

        if(!this.isActive(i * 1, j * 1)){
          this.$store.commit('setErrorKey', new Date(this.currYear, (i * 1 + i * 1 + i * 1 + j * 1), 1, 0, 0));
          return false;
        }

        this.$store.commit('setMonth', this.currYear+'-'+(i * 1 + i * 1 + i * 1 + j * 1 + 1));
        this.$store.commit('setView', 1)

      },
      isActive(i,j){

        return this.$checkDate(this.currYear+'-'+this.$getNullsBefore((i + i + i + j + 1)));

      },

      setYear(add){
        this.currYear = (this.currYear * 1) + (add * 1);
        this.$store.commit("setYear", this.currYear);
      }
    },
    mounted () {
      this.getData()
    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'


  </style>
