<template>
  <div>
    <div class="menu single">
      <a href="#" style="text-decoration: none" v-on:click.prevent="" v-html="$store.getters.data.events[0].time[0]+ '<br />' + $store.getters.data.events[0].time[1]"></a>
    </div>
    <div>
      <privateLocation />
    </div>
    <div v-if="!password">
      <privatePassword :callback="function() { setPassword() }"/>
    </div>
    <div v-if="password">
      <privateInput v-if="!$store.getters.isBooked"/>
      <privateSuccess v-else/>
      <privateList :key="$store.getters.isBooked"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    privateLocation () { return import('@/components/form/private/location') },
    privatePassword () { return import('@/components/form/private/password') },
    privateInput () { return import('@/components/form/private/input') },
    privateSuccess () { return import('@/components/form/private/success') },
    privateList () { return import('@/components/form/private/list') }
  },
  props: {
    date: String
  },
  data () {
    return {
      password: false
    }
  },
  methods: {
    setPassword () {
      this.password = true
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/menu.sass'

    .formPart
      margin-bottom: 12px

    td > div

      padding-bottom: 20px

      > span
        font-size: 8pt
        display: block
  </style>
