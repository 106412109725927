<template>

  <div :class="{flixFocus: focused}" :key="active.length" @mouseover="focused = true" @mouseleave="focused = false">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <ul class="terms">
    <li v-for="(term, index) in data.values" :class="{active: active[index]}" :key="index+key" @click="getClick(index)">
      {{ term.label }}
    </li>
    </ul>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>

import getLabel from '@/components/form/parts/label';

export default{

  props: {data: Object, callback: Function},
  components: {getLabel},

  data(){

    return{
      active: {},
      focused: false,
      key: new Date().getTime()
    }
  },

  methods: {
    getDefaultActive () {
      if (typeof this.data.value === 'object' && Object.keys(this.data.value).length) {
        this.active = Object.keys(JSON.parse(JSON.stringify(this.data.value)))
      } else {
        Object.keys(this.data.values).forEach(function(k){
          this.active[k] = false;
        }.bind(this));
      }
    },
    getActive(index){

      return this.active[index];
    },

    getClick(index){

      this.active[index] = !this.active[index];
      this.key = new Date().getTime();

      this.checkData();
    },

    checkData(){

      var error = false;

      Object.values(this.active).forEach(function(v){
        if(v == false){
          error = true
          return false;
        }
      });

      if(error){
        this.callback(false, this.data.required);
        return false;
      }

      var data = [];

      Object.values(this.data.values).forEach(function(v){

        data.push(v.value);

      });

      this.callback(
        data, this.data.required
      );
    }
  },

  mounted(){
    this.getDefaultActive()
  }
}

</script>

  <style lang="sass" scoped>
    @import '@/style/variables.sass'
    @import '@/style/form/label.sass'
    @import '@/style/form/terms.sass'
  </style>
