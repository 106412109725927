<template>

  <div v-if="day" :key="updateKey">

    <div class="menu single">

      <a href="#" v-on:click.prevent="getCalendar()" :title="$store.getters.l.backToCalendar" v-html="getDateString()"></a>
      <div class="appointmentAlert" :style="$getStyling(['color'], 'accent')" v-if="$store.getters.data.showAppointments">{{ getMaxApplicants() }}</div>
      <a href="#" class="info" v-on:click.prevent="getCalendar()">{{ $store.getters.l.backToCalendar }}</a>
    </div>

    <div style="float:none; clear:both"/>

    <serviceInfo />


    <div>

      <div v-for="(element, index) in formElements" :key="index" :style="{width: ((element.columnName/12) * 100)+'%', float:'left', padding:'5px', 'box-sizing':'border-box'}">
        <div class="formPart">

            <component :is="getFormPart(element.type)" v-if="getFormPart(element.type)" :data="element" :callback="function(data){ callback(data, element) }" />
            <div v-else v-html="element.type"></div>

        </div>
      </div>

      <div style="clear: both; float: none" />

      <submitBtn :key="form" :date="getDateString()"/>
    </div>

  </div>

</template>

<script>
import serviceInfo from '@/components/functions/serviceInfo';
import submitBtn from '@/components/form/parts/submitBtn';

export default{

  props: {date: String},
  components: {serviceInfo, submitBtn},

  data(){

    return{
      form: false,
      save: {},
      oClock: this.$store.getters.l.oClock,
      weekdays: this.$store.getters.weekdays,
      day: false,
      currDay: false,
      formElements: false,
      updateKey: 0
    }
  },
  mounted () {

    var date = this.$generateDate(this.date)
    this.day = date
    this.formElements = this.getFormElements()
    this.currDay = this.$getNullsBefore(date.getDate())+"."+this.$getNullsBefore(date.getMonth() + 1)+"."+date.getFullYear()
    this.updateKey = new Date().getTime()
  },
  methods:{

    getFormElements () {
      var r = {}
      var form = JSON.parse(JSON.stringify(this.$store.getters.data.form))

      var saveFormData = JSON.parse(JSON.stringify(this.$store.getters.formEntries))
      Object.keys(form).forEach(function (k) {
        r[k] = form[k]
        r[k].value = ''
        if (typeof saveFormData === 'object' && typeof saveFormData[form[k].label] !== 'undefined') {
          r[k].value = saveFormData[form[k].label]
        }
      })
      return r
    },

    getMaxApplicants(){

      if (this.$store.getters.data.type === 'datepicker') {
        return this.getMaxApplicantsDatepicker()
      } else {
        return this.getMaxApplicantsEventpicker()
      }
    },
    getMaxApplicantsEventpicker(){

      var maxApplicants = this.$store.getters.currentEvent.max_applicants

      return this.$store.getters.l.availibleApplicants.split('{count}').join((maxApplicants));
    },
    getMaxApplicantsDatepicker(){
      var newDay = new Date(this.day.getTime());
      var end = this.$getEndDate(newDay);
      var maxApplicants = this.$store.getters.approvedApplicants


      if (newDay.getTime() === end.getTime()) {
        maxApplicants = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]
      } else {
        var check = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())][2]
        var ma = maxApplicants[newDay.getFullYear() + '' + this.$getNullsBefore(newDay.getMonth() + 1) + '' + this.$getNullsBefore(newDay.getDate()) + '' + this.$getNullsBefore(newDay.getHours()) + '' + this.$getNullsBefore(newDay.getMinutes())]
          for (var j = 0; j <= ((end.getTime() - newDay.getTime()) / (60 * 1000) ); j+=5) {
            var nd = new Date(newDay.getTime())
          if(nd.getTime() > end.getTime()) {
            continue;
          }
          nd.setMinutes(nd.getMinutes() * 1 + j);
          var check2 = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
          if (check < check2[0]) {
            check = check2[0]
            ma = maxApplicants[nd.getFullYear() + '' + this.$getNullsBefore(nd.getMonth() + 1) + '' + this.$getNullsBefore(nd.getDate()) + '' + this.$getNullsBefore(nd.getHours()) + '' + this.$getNullsBefore(nd.getMinutes())]
          }
        }
        maxApplicants = ma
      }
      return this.$store.getters.l.availibleApplicants.split('{count}').join((maxApplicants[1] - maxApplicants[0]));
    },
    callback(data, element){

      var save = {};
      save[ element.label ] = data;
      this.$store.commit('saveForm', save);

      this.form = new Date().getTime();
    },

    getFormPart(type){


      if( Object.keys(this.$store.getters.getForm).indexOf(type) == -1 )
        return false;


      return this.$store.getters.getForm[type];

    },
    getFormElement(type){

      return this.$store.getters.getForm[type];

    },

    getCalendar(){
      var date = this.$generateDate(this.date);
      date.setMinutes(0);

      this.$store.commit('setHour', date);
      this.$store.commit('setView', -1)

    },

    getWeekday(){

      var weekday = this.day.getDay();
      weekday -= 1
      if(weekday < 0)
        weekday = 6;

      return this.weekdays[weekday];
    },

    getDateString(){

      var data = this.$store.getters.data;

      if(data.type == "datepicker")
        return this.getWeekday()[0]+this.getWeekday()[1]+', '+this.currDay+' – '+this.getDatePickerDate()+' '+this.$store.getters.l.oClock
      if(data.type == "eventpicker")
        return this.$store.getters.currentEvent.time[0] + '<br/>' + this.$store.getters.currentEvent.time[1]
    },

    getDatePickerDate(){

      var end = this.$getEndDate(this.day);

      var r = "";
      r += this.$getNullsBefore(this.day.getHours())+":"+this.$getNullsBefore(this.day.getMinutes());

      if(end.getTime() == this.day.getTime())
        return r;

      r += ' - ';
      r += this.$getNullsBefore(end.getHours())+":"+this.$getNullsBefore(end.getMinutes() );

      return r;

    }

  }

}

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/menu.sass'

    .formPart
      margin-bottom: 12px
    .appointmentAlert
      font-family: $font
      color: $active
      text-align: center
    td > div

      padding-bottom: 20px

      > span
        font-size: 8pt
        display: block

  </style>
