<template>
  <div>
    <transition name="fadeIn">
      <div :key="click" class="infocontainer" ref="calendar">
        <div class="subtext" v-if="subtext != $store.getters.data.title">{{ subtext }}</div>
          <slot></slot>
        <a href="#" class="infobox" ref="infobox" v-if="!click" @click.prevent="setClick">
          <div class="infocontent" :style="$getStyling(['background-color'], 'accent')">
            <div class="icon" :style="$getStyling(['color'], 'accent')">!</div>
            <div v-html="subtext"></div>
            <div v-if="appendinfo">
              <hr>
              <div class="appendinfo" v-html="appendinfo"></div>
            </div>
          </div>
        </a>
      </div>
    </transition>
  </div>
</template>
<script>
import { info } from '@/components/info/calculate_info.js'

export default {
  components: {},
  props: {},
  data () {

    var rollOut = true;

    if( this.$store.getters.data.user === '8f14e45fceea167a5a36dedd4bea2543' ) {
      rollOut = false;
    }

    if( this.$store.getters.data.user === '1679091c5a880faf6fb5e6087eb1b2dc' ) {
      rollOut = false;
    }

    rollOut = false
    return {
      text: false,
      subtext: false,
      click: rollOut,
      info: false,
      error: false,
      appendinfo: '',
      timeout: 2000,
      timeouts: {}
    }
  },
  methods: {
    setClick () {
      this.click = true
      if (!this.info.click) {
        this.info.click += 1
        this.$store.commit('setInfo', this.info)
      }
    },
    getText () {

      var i = new info()
      i.store = this.$store
      i.t = this.$t
      i.click = this.info.click
      i.getText()

      this.text = i.text
      this.subtext = i.subtext
    },
    showDefaultInfobox () {
      this.info = this.$store.getters.info
      this.getText()
      this.animateInfoBox()
    },
    showErrorInfobox () {
      var ek = this.$store.getters.errorKey;
      var cd = this.$checkDate(ek.getFullYear() + '-' + this.$getNullsBefore((ek.getMonth() + 1)) + '-' + this.$getNullsBefore(ek.getDate()) + ' ' + this.$getNullsBefore(ek.getHours()) + ':' + this.$getNullsBefore(ek.getMinutes()));
      if(cd){
        ek = this.$getEndDate(ek)
        this.$checkDate(ek.getFullYear() + '-' + this.$getNullsBefore((ek.getMonth() + 1)) + '-' + this.$getNullsBefore(ek.getDate()) + ' ' + this.$getNullsBefore(ek.getHours()) + ':' + this.$getNullsBefore(ek.getMinutes()));
      }
      this.subtext = this.$store.getters.l['bookingNotPossible']
      this.appendinfo = this.$store.getters.l.error + ": "
      this.appendinfo += this.$store.getters.l[window.debugBookingCalendar[window.debugBookingCalendar.length - 1].text];
      this.appendinfo += ' ';
      // this.appendinfo += '<div class="answer">' + this.$store.getters.l[window.debugBookingCalendar[window.debugBookingCalendar.length - 1].status]+'</div>'
      this.appendinfo += '<div class="answer"></div>'
      this.animateInfoBox()
    },
    animateInfoBox () {
      if(typeof this.timeouts[0] !== 'undefined'){
        clearTimeout(this.timeouts[0])
      }
      if(typeof this.timeouts[1] !== 'undefined'){
        clearTimeout(this.timeouts[1])
      }
      this.timeouts[0] = setTimeout(function () {
        if ( this.$refs.calendar && this.$refs.calendar.offsetHeight > 600 && typeof this.$refs.infobox !== 'undefined' ) {
          this.$refs.infobox.classList.add('top')
        }
        if (typeof this.$parent.$parent.$refs.app !== "undefined" && this.$parent.$parent.$refs.app.offsetTop !== 0) {
          window.scrollTo({
            top: this.$parent.$parent.$refs.app.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
       }
      }.bind(this), 100 )

      this.timeouts[1] = setTimeout(function () {
        this.setClick();
      }.bind(this), this.timeout)
    }
  },
  watch: {
    '$store.getters.errorKey' () { this.click = false; this.timeout = 5000; this.error = true; this.showErrorInfobox() }
  },
  mounted () {
    this.showDefaultInfobox()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/info.sass'
</style>
