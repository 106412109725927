import Vue from 'vue'
import makeStore from '../store'
import eventPickerDates from './eventpicker/dates.js'

var store = makeStore();

var eventPickerRules = function(data){

  this.atts = data;


  this.getCheckDate = function(dateString){

    this.begin = this.generateDate(dateString.begin);
    this.begin.Ymd = this.begin[0];
    this.begin.Hi = this.begin[1];
    this.begin = new Date(this.begin.Ymd[0], this.begin.Ymd[1]-1, this.begin.Ymd[2], this.begin.Hi[0], this.begin.Hi[1], 0,0,0,0 );

    this.end = this.generateDate(dateString.end);
    this.end.Ymd = this.end[0];
    this.end.Hi = this.end[1];
    this.end = new Date(this.end.Ymd[0], this.end.Ymd[1]-1, this.end.Ymd[2], this.end.Hi[0], this.end.Hi[1], 0,0,0,0 );


    var r = new eventPickerDates(this.atts);
    r.begin = this.begin;
    r.end = this.end;
    r.checkDate();

    return { time: this.beautifyDate(), active: !r.error, begin: this.begin, end: this.end };
  },


  this.beautifyDate = function(){

    if(this.begin.getDate() == this.end.getDate() && this.begin.getMonth() == this.end.getMonth() && this.begin.getFullYear() == this.end.getFullYear())
      return this.dateSameDay();

    return this.dateOtherDay();

  };

  this.dateOtherDay = function(){

    var r = ["", ""];

    var weekday = this.begin.getDay();

    if(weekday == 0)
      weekday = 7;

    weekday = weekday -1;

    r[0] += store.getters.weekdays[weekday]+", ";
    r[0] += Vue.prototype.$getNullsBefore(this.begin.getDate())+".";
    r[0] += Vue.prototype.$getNullsBefore(this.begin.getMonth()+1)+".";

    r[0] += Vue.prototype.$getNullsBefore(this.begin.getFullYear());

    r[0] += ", "+Vue.prototype.$getNullsBefore(this.begin.getHours())

    if(this.begin.getMinutes())
      r[0] += ":"+Vue.prototype.$getNullsBefore(this.begin.getMinutes());

    r[0] += " "+store.getters.oClock;

    weekday = this.end.getDay();


    if(weekday == 0)
      weekday = 7;

    weekday = weekday -1;

    r[1] += " "+store.getters.until+" "+store.getters.weekdays[weekday]+", ";
    r[1] += Vue.prototype.$getNullsBefore(this.end.getDate())+".";
    r[1] += Vue.prototype.$getNullsBefore(this.end.getMonth()+1)+".";

    r[1] += Vue.prototype.$getNullsBefore(this.end.getFullYear());

    r[1] += ", "+Vue.prototype.$getNullsBefore(this.end.getHours())

    if(this.begin.getMinutes())
      r[1] += ":"+Vue.prototype.$getNullsBefore(this.end.getMinutes());

    r[1] += " "+store.getters.oClock;

    return r;
  },

  this.dateSameDay = function(){

      var r = ["", ""];

      var weekday = this.begin.getDay();

      if(weekday == 0)
        weekday = 7;

      weekday = weekday -1;

      r[0] += store.getters.weekdays[weekday]+", ";
      r[0] += Vue.prototype.$getNullsBefore(this.begin.getDate())+".";
      r[0] += Vue.prototype.$getNullsBefore(this.begin.getMonth()+1)+".";

      r[0] += Vue.prototype.$getNullsBefore(this.begin.getFullYear());

      r[1] += Vue.prototype.$getNullsBefore(this.begin.getHours())

      if(this.begin.getMinutes())
        r[1] += ":"+Vue.prototype.$getNullsBefore(this.begin.getMinutes());

      if( this.end.getHours() != this.begin.getHours() ){
        r[1] += " - "+Vue.prototype.$getNullsBefore(this.end.getHours())

        if(this.end.getMinutes())
          r[1] += ":"+Vue.prototype.$getNullsBefore(this.end.getMinutes());
      }

      r[1] += " "+store.getters.oClock;

      return r;
  },


  this.generateDate = function(dateString){

    dateString = dateString.toString();

    dateString = dateString.split(" ");

    var Ymd = dateString[0].split( "-" );
    var His = [];

    if(typeof dateString[1] == "string")
      His = dateString[1].split(":");

    return [Ymd, His];
  };



}

export default eventPickerRules
