var lang =
{
  brand: "&copy; bookingflix.com",
  submit: "senden",
  check: 'überprüfen',
  ago: "vor {date}",
  inDate: "in ca. {date}",
  years: "Jahren",
  year: "Jahr",
  months: "Monaten",
  month: "Monat",
  days: "Tagen",
  day: "Tag",
  hour: "Stunde",
  hours: "Stunden",
  minute: "Minute",
  minutes: "Minuten",
  second: "Sekunde",
  seconds: "Sekunden",
  lessSeconds: "wenigen Augenblicken",
  monthsNames: ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  oClock: "Uhr",
  weekdays: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag" ],
  until: "bis",
  today: "heute",
  change: "ändern",
  pleaseFillOut: "Bitte ausfüllen",
  isRequired: "Pflichtfeld",
  findDate: "Datum finden",
  backToCalendar: "zurück zur Terminauswahl",
  backToHourView: "zur Stundenübersicht",
  backToDayView: "zur Tagesübersicht",
  backToMonthView: "zur Monatsübersicht",
  backToYearView: "zur Jahresübersicht",
  formFillOut: "Bitte zurvor Formular ausfüllen",
  saveDate: "Termin jetzt vormerken",
  loading: "Lade Kalender",
  showMoreAppointments: "Weitere Termine anzeigen",
  noEventsAvailable: "Aktuell sind keine Termine verfügbar",
  bookingNotPossible: "Terminbuchung an diesem Datum nicht möglich. Bitte ein alternatives Datum auswählen.",
  successHeader: "Vielen Dank. Der gewünschte Termin ist jetzt vorgemerkt.",
  successHint: "Eine Bestätigungsmail wurde gesendet. Bitte E-Mailpostfach (und eventuell Spam-Ordner) überprüfen.",
  successAction: "Einfach innerhalb der nächsten {verifytime} den Bestätigungslink in der E-Mail klicken. Erst dann wird die Terminanfrage endgültig gespeichert.",
  debugEnd: 'Ist Prüfdatum in der Vergangenheit?',
  debugExcludeBookings: 'Ist Prüfdatum bereits ausgebucht?',
  debugExcludeDates: 'Fällt Prüfdatum in Ferien / Freizeit?',
  debugLastDayBooking: 'Ist Prüfdatum größer als der letztmögliche Termin für diesen Tag?',
  debugLastMinuteBooking: 'Ist Prüfdatum zu nah am Termin (Last-Minute)?',
  debugPause: 'Ist Prüfdatum innerhalb der definierten Pausenzeiten?',
  debugPeriodRange: 'Ist Prüfdatum außerhalb des verfügbaren Datumsbereichs?',
  debugTotalEnd: 'Ist Prüfdatum nach dem letzmöglichen Datum?',
  debugTotalStart: 'Ist Prüfdatum früher als das frühstmögliche Buchungsdatum?',
  debugWeekday: 'Ist Prüfdatum außerhalb des Wochentags-Zeitraums?',
  debugDates: 'Ist Prüfdatum außerhalb des Event-Zeitraums?',
  debugProblemsEnd: 'Probleme traten beim Terminende auf.',
  error: 'Fehler',
  no: 'nein',
  yes: 'ja',
  openCalendar: 'Terminkalender jetzt öffnen',
  selectTime: 'Bitte Zeitraum auswählen',
  selectDay: 'Bitte gewünschten Tag auswählen',
  selectMonth: 'Bitte gewünschten Monat auswählen',
  selectYear: 'Bitte gewünschtes Jahr auswählen',
  selectAppointment: 'Bitte exakten Wunschtermin auswählen',
  selectService: 'Bitte Service auswählen',
  fillForm: 'Bitte Termin-Buchungs-Formular ausfüllen',
  step: 'Schritt {step}/{of}',
  legend: 'Legende',
  bookingDate: 'Buchbarer Termin',
  noBookingDate: 'Termin nicht buchbar',
  password: 'Passwort',
  myName: 'Mein Name',
  myComment: 'Kommentar beifügen (optional)',
  commitments: 'Ich nehme teil',
  cancellation: 'Ich nehme nicht teil',
  anonymInfo: 'Alle Terminzusagen und -absagen werden anonymisiert dargestellt',
  availibleApplicants: 'Noch {count} Termine buchbar',
  addAppointment: 'Zusätzlichen Termin-Wunsch eintragen',
  terms: 'AGB',
  datepickerStartBtn: 'Jetzt Termin buchen'
}

export default { lang }
