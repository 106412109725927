<template>

  <div>

    <a href="#" v-on:click.prevent="function(){ if(!error) callback() }" :class="{flixError: error}">

      <span v-if="error">
        <b>{{ $store.getters.l.pleaseFillOut }}:</b><br>
        <div v-for="h in hint" :key="h">- {{ h }}</div>
      </span>
      <span v-else>
        <b>{{ $store.getters.l.saveDate }}</b><br>
        <span v-html="date"></span></span>
    </a>
  </div>

</template>

<script>


export default{

  props: {date: String, active: Boolean},

  data(){

    return {
      error: true,
      hint: [],
      metadata: {}
    }
  },

  methods:{

    checkData(){

      this.error = false;
      var entries = this.$store.getters.formEntries;


      this.$store.getters.data.form.forEach(function(v){

        if( v.required == true ){

          if( typeof entries == "undefined" || typeof entries[v.label] == "undefined" || !entries[v.label] ){
            this.error = true;
            this.hint.push(v.label);
          }

        }

        if( typeof entries !== "undefined" && typeof entries[v.label] !== "undefined" && entries[v.label] ){
          if(v.subtype == "email" || v.subtype == "tel"){
            this.metadata[ v.subtype ] = entries[v.label];
          }
        }

      }.bind(this));


    },

    callback(){

      var data = this.$store.getters.data;
      var date;

      if(data.type == "datepicker"){
        date = this.$store.getters.date;
        date = date.split(" ");
        date[0] = date[0].split("-");
        date[1] = date[1].split(":");
        date = new Date(date[0][0], date[0][1]-1, date[0][2], date[1][0], date[1][1], 0);

        this.metadata.begin = date;
        this.metadata.end = this.$getEndDate(date);
      }
      if(data.type == "eventpicker"){
        date = this.$store.getters.event;
        this.metadata.begin = date.begin;
        this.metadata.end = date.end;
      }

      this.metadata.date = new Date();
      this.metadata.service = this.$store.getters.currentService;
      this.metadata.data = this.$store.getters.formEntries;

      if(typeof data.user != "undefined")
        this.metadata.user = data.user;

      if(typeof data.ID != "undefined")
        this.metadata.ID = data.ID;

      var callback = this.$store.getters.callback;

      callback.setData(this.metadata);
      callback.prepareSend();
      callback.setCallback(function(ret){ this.$store.commit("setSave", ret) }.bind(this));


    },



    getDateToString(date){

      return date.getFullYear()+"-"+this.$getNullsBefore(date.getMonth()+1)+"-"+this.$getNullsBefore(date.getDate())+" "+this.$getNullsBefore(date.getHours())+":"+this.$getNullsBefore(date.getMinutes())+":00";

    }
  },

  mounted() {

    this.checkData()
  }
}

</script>


<style lang="sass" scoped>

  @import '@/style/variables.sass'

  a
    background-color: $active
    display: block
    padding: 10px
    text-align: center
    color: white
    box-sizing: border-box
    text-decoration: none
    font-family: $font
    border: 1px solid $active
    transition: all 0.5s ease-in
    width: 100%

    div
      font-size: 9pt

    &:hover
      background-color: transparent
      color: $active

    &.flixError
      background-color: $error
      border-color: $error

      &:hover
        background-color: transparent
        color: $error

</style>
