import Vue from 'vue';

var checkPeriodRange = function(atts){

  this.error = false;
  this.atts = atts;
  this.debug = {text: 'debugPeriodRange', status: "no", error: false}

  this.check = function(){

    if( atts.include.period != 'undefined'){
      this.checkPeriodEnd();
    }
    window.debugBookingCalendar.push(this.debug)
  }

  this.checkPeriodEnd = function(){

    var d = new Date();


    if( typeof atts.start == "string" ){
      var d2 = atts.start;
      d2 = d2.split(" ");
      d2 = d2[0].split("-");

      d2 = new Date(d2[0], d2[1] - 1, d2[2], 0, 0, 0);

      if(d2.getTime() > d.getTime())
        d = d2;

    }

    if(typeof atts.include.period == "undefined")
      return false;

    switch( atts.include.period[1] ){

      case "days":
        d.setDate(d.getDate() + ( (atts.include.period[0] * 1) ));
      break;

      case "weeks":
        d.setDate(d.getDate() + (7 * (atts.include.period[0] * 1) ));
      break;

      case "months":
      d.setMonth(d.getMonth() + (atts.include.period[0] * 1) );
      break;

      case "years":
      d.setYear(d.getFullYear() + (atts.include.period[0] * 1) );
      break;

    }


    var date1 = this.date.getFullYear()+""+Vue.prototype.$getNullsBefore(this.date.getMonth() + 1)+""+Vue.prototype.$getNullsBefore(this.date.getDate())+"0000";
    var date2 = d.getFullYear()+""+Vue.prototype.$getNullsBefore(d.getMonth() + 1)+""+Vue.prototype.$getNullsBefore(d.getDate())+"0000";

    if( (date1 * 1) > (date2 *1)){
      this.error = true
      this.debug.status = "yes";
      this.debug.error = true;
    }


  }

}

export default checkPeriodRange;
