import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import de from '@/store/de_DE.js'
import en from '@/store/en_EN.js'

export default function makeStore() {

  return new Vuex.Store({
  state: {
    multiCalendar: {},
    save: false,
    callback: {},
    currentView: 2,
    viewBox: "calendar",
    datepickerStartBtn: false,
    date: "",
    data: {},
    currentService: {},
    services: {},
    approvedDates: {},
    approvedDatesEnd: {},
    approvedApplicants: {},
    privateBookings: [],
    setBooking: false,
    debugDates: {},
    saveFormData: {},
    currentEvent: {},
    errorKey: '',
    info: {
      click: 0
    },
    searchDate: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    },
    views:{
      calendar: {
        decade(){ return import('@/components/view/decade.vue') },
        year(){ return import('@/components/view/year.vue') },
        month(){ return import('@/components/view/month.vue') },
        day(){ return import('@/components/view/day/day.vue') },
        minutes(){ return import('@/components/view/day/minutes.vue') },
        form(){ return import('@/components/form/index.vue') },
      },
      list: {
        list(){ return import('@/components/view/list/list.vue') },
        minutes(){ return import('@/components/view/day/minutes.vue') },
        form(){ return import('@/components/form/index.vue') },
      },
      events: {
        events(){ return import('@/components/view/events/events.vue') },
        form(){ return import('@/components/view/events/form.vue') },
      }
    },

    language: "de",

    translation: {
      en: en.lang,
      de: de.lang
    },

    form: {
      header(){ return import('@/components/form/parts/header.vue') },
      text(){ return import('@/components/form/parts/text.vue') },
      textarea(){ return import('@/components/form/parts/textarea.vue') },
      paragraph(){ return import('@/components/form/parts/paragraph.vue') },
      terms(){ return import('@/components/form/parts/terms.vue') },
      select(){ return import('@/components/form/parts/select.vue') },
      checkbox_group() { return import('@/components/form/parts/checkbox.vue') },
      checkbox() { return import('@/components/form/parts/checkbox.vue') },
      radio_group() { return import('@/components/form/parts/radio.vue') },
      radio() { return import('@/components/form/parts/radio.vue') },
      agb() { return import('@/components/form/parts/agb.vue') }
    }
  },
  mutations: {
    setData(store, data){ store.data = data.data; if(typeof data.data.privateBookings != "undefined"){ store.privateBookings = JSON.parse(JSON.stringify(data.data)).privateBookings; } if(typeof data.services != "undefined"){ store.services = data.services; } if(typeof data.settings != "undefined"){ Object.keys(data.settings).forEach(function(key){ store[key] = data.settings[key]; }); } },
    setServices(store, services){ store.services = services; },
    setEvent(store, event){ store.currentEvent = event; },
    setSettings(store, settings){ Object.keys(settings).forEach(function(key){ store[key] = settings[key]; }); },
    setDate(store, d){ if(typeof d == 'undefined' || !d){ d = new Date(); } store.date = d.getFullYear()+"-"+(d.getMonth() + 1)+"-"+d.getDate()+" "+d.getHours()+":00"; },
    setView(store, add){ if(store.currentView + add >= 0) { store.currentView = store.currentView + add; } },
    setDay(store, date){ store.date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();},
    setHour(store, date){ store.date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+" "+date.getHours()+":"+date.getMinutes(); },
    setMonth(store, date){ store.date = date+"-01"; },
    setYear(store, date){ store.date = date+"-01-01"; },
    setService(store, service){ store.currentService = service; },
    changeDate(store, d){ store.searchDate = {year: d.getFullYear(), month: (d.getMonth() + 1), day: d.getDate()}; store.date = d.getFullYear()+"-"+(d.getMonth() + 1)+"-"+d.getDate(); store.currentView = 3; },
    approvedDate(store, date){ store.approvedDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] = date.state; },
    approvedDateEnd(store, date){ store.approvedDatesEnd[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] = date.state; },
    approvedApplicants(store, date) { store.approvedApplicants[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] = date.state; },
    debugDate(store, date){ store.debugDates[ date.Y+""+date.m+""+date.d+""+date.H+""+date.i ] = date.state;},
    saveForm(store, data){ store.saveFormData = Object.assign(store.saveFormData, data)  },
    setCallback(store, data){ store.callback = data },
    setSave(store, data){ store.save = data; },
    setLanguage(store, lang){ store.language = lang },
    setInfo(store, data) { store.info = data },
    setCalendars(store, data) { store.multiCalendar = data },
    setErrorKey(store, key) { store.errorKey = key },
    setBooking(store, booking) { store.privateBookings.splice(0, 0, booking); store.setBooking = true },
    cleanApprovedDates(store){ store.approvedDates = {}; store.approvedDatesEnd = {} },
    setDatpickerStartBtn(store) { store.datepickerStartBtn = true }
  },
  actions: {
  },
  modules: {
  },
  getters:{
    getForm(store){ return store.form },
    l(state) { if(typeof state.translation[state.language] == "object"){ return Object.assign(state.translation["de"], state.translation[state.language]) } return state.translation["de"]; },
    getLanguage(state) { return state.language },
    until(state, getters){ return getters.l.until },
    weekdays(state, getters) {  return getters.l.weekdays },
    oClock(state, getters) { return getters.l.oClock },
    data(store){ return store.data; },
    date(store){ return store.date; },
    views(store){ return store.views },
    currentView(store){ return store.views[store.viewBox][ Object.keys(store.views[store.viewBox])[store.currentView] ] },
    approvedDates(store){ return store.approvedDates },
    approvedDatesEnd(store){ return store.approvedDatesEnd },
    approvedApplicants(store) { return store.approvedApplicants },
    debugDates(store){ return store.debugDates },
    searchDate(store){ return store.searchDate },
    viewBox(store){ return store.viewBox },
    services(store){ return store.services },
    currentService(store){ return store.currentService },
    currentEvent(store) { return store.currentEvent },
    formEntries(store){ return store.saveFormData },
    event(store){ return store.currentEvent },
    callback(store){ return store.callback },
    save(store){ return store.save },
    saveFormData(store) { return store.saveFormData },
    info(store) { return store.info },
    errorKey(store) { return store.errorKey },
    privateBookings(store) { return store.privateBookings },
    isBooked(store) { return store.setBooking },
    datepickerStartBtn(store) { return store.datepickerStartBtn }
  }
})

}
