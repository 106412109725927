<template>
  <div>
    <component :is="get_component" :key="$store.getters.date" />
  </div>
</template>

<script>
import hours from '@/components/view/day/hours'
import services from '@/components/view/services/services'

  export default{

    components: {
      hours, services
    },

    computed: {

      get_component(){


        var atts = this.$store.getters.data;

        var date = this.$generateDate(this.$store.getters.date);
        var weekday = date.getDay();

        if( weekday == 0 )
          weekday = 7;

        weekday--;

        if(typeof atts.include.weekdays[weekday] == "undefined"){
          this.$store.commit('setView', -1)
          return false;
        }


        if( !isNaN(parseInt( atts.include.weekdays[weekday][2] )) ){

          this.$store.commit("setDay", date);
          return "hours";
        }


        return "services";


      }

    }
  }
</script>
