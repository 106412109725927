import Vue from 'vue'
import App from './App.vue'
import store from './store'
import plugin_main from '@/plugins/main.js'

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'


Vue.config.productionTip = false

Vue.use(plugin_main);
Vue.use(vueCustomElement);


App.store = store
Vue.customElement('hit-calendar', App)
