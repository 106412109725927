var lang =
{
  brand: "&copy; bookingflix.com",
  submit: "senden",
  check: 'überprüfen',
  ago: "vor {date}",
  inDate: "in ca. {date}",
  years: "Jahren",
  year: "Jahr",
  months: "Monaten",
  month: "Monat",
  days: "Tagen",
  day: "Tag",
  hour: "Stunde",
  hours: "Stunden",
  minute: "Minute",
  minutes: "Minuten",
  second: "Sekunde",
  seconds: "Sekunden",
  lessSeconds: "wenigen Augenblicken",
  monthsNames: ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  oClock: "Uhr",
  weekdays: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
  until: "bis",
  today: "heute",
  change: "ändern",
  pleaseFillOut: "Bitte ausfüllen",
  isRequired: "Pflichtfeld",
  findDate: "Datum finden",
  backToCalendar: "zurück zur Terminauswahl",
  backToHourView: "zur Stundenübersicht",
  backToDayView: "zur Tagesübersicht",
  backToMonthView: "zur Monatsübersicht",
  backToYearView: "zur Jahresübersicht",
  formFillOut: "Bitte zurvor Formular ausfüllen",
  saveDate: "Termin jetzt buchen",
  loading: "Lade Kalender",
  showMoreAppointments: "Weitere Termine anzeigen",
  noEventsAvailable: "Aktuell sind keine Termine verfügbar",
  bookingNotPossible: "Terminbuchung an diesem Datum nicht möglich. Bitte ein alternatives Datum auswählen.",
  successHeader: "Noch ein Schritt... Bitte den gewünschten Termin jetzt bestätigen.",
  privateSuccessHeader: "Sehr gut! Der Veranstalter wurde über diesen neuen Eintrag informiert.",
  successHint: "Eine Bestätigungsmail wird in den nächsten Minuten an {email} gesendet.", /* 2020-04-22 */
  successAction: "Bitte innerhalb der nächsten {verifytime} den Bestätigungslink in der E-Mail klicken. Erst dann wird die Terminanfrage endgültig gespeichert.", /* 2020-04-22 */
  debugEnd: 'Termin liegt in der Vergangenheit',
  debugExcludeBookings: 'Termin ist bereits ausgebucht',
  debugExcludeDates: 'Termin ist in Ferien / Freizeit',
  debugLastDayBooking: 'Uhrzeit liegt nach dem letztmöglichen Termin für diesen Tag',
  debugLastMinuteBooking: 'Datum zu nah am Termin (Last-Minute)',
  debugPause: 'Termin kollidiert mit Pausenzeiten',
  debugPeriodRange: 'Termin ist außerhalb des verfügbaren Datumsbereichs',
  debugTotalEnd: 'Termin ist nach dem letzmöglichen Buchungsdatum',
  debugTotalStart: 'Termin ist vor dem frühstmöglichen Buchungsdatum',
  debugWeekday: 'Wochentag steht nicht zur Terminbuchung zur Verfügung',
  debugDates: 'Termin außerhalb des Event-Zeitraums',
  debugProblemsEnd: 'Probleme traten beim Terminende auf.',
  error: 'Fehler',
  no: 'nein',
  yes: 'ja',
  openCalendar: 'Terminkalender jetzt öffnen',
  selectTime: 'Bitte Zeitraum auswählen',
  selectDay: 'Bitte gewünschten Tag auswählen',
  selectMonth: 'Bitte gewünschten Monat auswählen',
  selectYear: 'Bitte gewünschtes Jahr auswählen',
  selectAppointment: 'Bitte exakten Wunschtermin auswählen',
  selectService: 'Bitte Service auswählen',
  fillForm: 'Bitte Termin-Buchungs-Formular ausfüllen',
  step: 'Schritt {step}/{of}',
  legend: 'Legende',
  bookingDate: 'Buchbarer Termin',
  noBookingDate: 'Termin nicht buchbar',
  password: 'Passwort',
  myName: 'Mein Name',
  myComment: 'Kommentar beifügen (optional)',
  commitments: 'Ich nehme teil',
  cancellation: 'Ich nehme nicht teil',
  anonymInfo: 'Alle Terminzusagen und -absagen werden anonymisiert dargestellt',
  availibleApplicants: 'Noch {count} Termin(e) buchbar',
  addAppointment: 'Zusätzlichen Termin-Wunsch eintragen',
  terms: 'AGB',
  datepickerStartBtn: 'Jetzt Termin buchen',
  successfullSaved: 'Erfolgreich gespeichert',
  savedInfo: 'Der Termin ist jetzt im System hinterlegt und der Terminverwalter über die Terminanfrage informiert worden.',
  savedLinkText: 'Bearbeitungsstatus und alle Termininfos anzeigen'
}

export default { lang }
