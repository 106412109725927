<template>
  <div>

    <transition name="getView">
      <info :key="$store.getters.date + $store.state.currentView + JSON.stringify($store.getters.currentService)">
        <div>
          <calendarTitle />
          <component v-bind:is="$store.getters.currentView" :date="date" v-if="date"/>
          <!-- <calendarLegend /> -->
        </div>
      </info>
    </transition>

  </div>
</template>

<script>

import calendarTitle from '@/components/functions/showTitle'
// import calendarLegend from '@/components/legend'
import info from '@/components/info'

export default {
  name: 'eventpicker',

  components: { calendarTitle, info, /* calendarLegend */ },

  mounted(){
    this.$store.commit("setSettings", {viewBox: "events"});
    this.$store.commit("setSettings", {currentView: 0});

  },
  data () {
    return {
      date: this.generateDate()
    }
  },
  methods: {
    generateDate () {
      var d = this.$store.getters.data.events[0].begin;

      if(typeof this.$store.getters.data.events[0].begin === 'string'){
        d = this.$generateDate(this.$store.getters.data.events[0].begin)
      }
      return d.getFullYear() + '-' + (d.getMonth() * 1 + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
    }
  }

}
</script>
