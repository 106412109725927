<template>

  <div>

    <table cellspacing="0" cellpadding="0" v-if="data && data.length">
      <transition name="getDates">
        <tbody>
        <tr v-if="$store.getters.data.image"><td class="flix-text-center"><a href="#" v-on:click.prevent="selectEvent(data[0])"><img :style="$getStyling(['border-color'], 'accent')" :src="$store.getters.data.image" /></a></td></tr>
        <tr v-for="(event, index) in data" v-bind:key="index">
          <td>
            <div v-bind:class="{active: event.active}"
            v-on:click.prevent="selectEvent(event)"
            :style="$getStyling(['border-bottom-color'], 'accent')"
            @mouseenter="removeStyle"
            @mouseleave="setStyle"
            >
              <counter :event="event"/>
              {{ event.time[0] }}
              <span class="subdate">{{ event.time[1] }}</span>
              <i v-if="$store.getters.data.showAppointments" :style="$getStyling(['color'], 'accent')">– {{ $store.getters.l.availibleApplicants.split('{count}').join(event.max_applicants) }} –</i>
            </div>
          </td>
        </tr>
      </tbody>
    </transition>

    </table>

    <div v-else class="error">
      {{ $store.getters.l.noEventsAvailable }}
    </div>


  </div>

</template>

<script>


export default{

  components: {
    counter() { return import('@/components/functions/counter') }
  },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {

      this.data = [];
      var i = 0;

      JSON.parse(JSON.stringify(this.$store.getters.data)).events.forEach(function(v){

        var date = this.$eventpickerDate(v);
        v = Object.assign(v, date)
        v.max_applicants = this.getMaxApplicants(i)

        if(v.max_applicants * 1 >= 0 && date && date.active) {
          this.data.push(v);
        }

        i++;

      }.bind(this));



    },
    getBookingAppointments (events){
      var check = 0
      if(!this.events.length) {
        return check;
      }
      events.forEach(function (e) {
        check += e.maxApplicants * 1;
      })
      return check
    },
    getMaxApplicants(id){
      var maxApplicants = this.$store.getters.data.max_applicants[id]

      if (typeof this.$store.getters.data.exclude !== 'object' || typeof this.$store.getters.data.exclude.bookings !== 'object' || !Object.keys(this.$store.getters.data.exclude.bookings).length) {
        return maxApplicants
      }

      maxApplicants = this.getCheckDate(id, maxApplicants)
      return maxApplicants;
    },
    getCheckDate (e, maxApplicants) {

      var ev = JSON.parse(JSON.stringify(this.$store.getters.data))
      var newMaxApplicants = maxApplicants
        var event = ev.events[e]
        newMaxApplicants = ev.max_applicants[e]
        event.begin = this.$generateDate(event.begin)
        event.end = this.$generateDate(event.end)

        if (typeof this.$store.getters.data.exclude === 'object' && typeof this.$store.getters.data.exclude.bookings === 'object' && Object.keys(this.$store.getters.data.exclude.bookings).length ){
          this.$store.getters.data.exclude.bookings.forEach(function (book) {
            var begin = this.$generateDate(book[0])
            var end = this.$generateDate(book[4])
            if(event.begin.getTime() === begin.getTime() && event.end.getTime() === end.getTime()){
              newMaxApplicants = ((book[2] * 1) - (book[1] * 1))
            }
        }.bind(this))
      }

      return newMaxApplicants

    },
    removeStyle (event) {
      var color = this.$getStyling(['color'], 'accent')
      if (typeof event === 'object' && typeof event.target !== 'undefined' && color) {
        if(typeof event.target.style === 'undefined') {
          event.target.style = {}
        }
        event.target.style['background-color'] = color.color
        if(typeof event.target.getElementsByTagName('i') === 'object' && event.target.getElementsByTagName('i').length){
          event.target.getElementsByTagName('i')[0].style.color = '#ffffff'
        }
      }
    },
    setStyle (event) {
      var color = this.$getStyling(['color'], 'accent')
      if (typeof event === 'object' && typeof event.target !== 'undefined' && color) {
        if(typeof event.target.style === 'undefined') {
          event.target.style = {}
        }
        event.target.style['background-color'] = 'inherit'
        if(typeof event.target.getElementsByTagName('i') !== 'undefined' && event.target.getElementsByTagName('i').length){
          event.target.getElementsByTagName('i')[0].style.color = color.color
        }
      }
    },
    selectEvent(event) {

      this.$store.commit("setEvent", event);

      this.$store.commit("setView", 1);


    }

  }

}

</script>

<style lang="sass" scoped>

  @import '@/style/variables.sass'
  @import '@/style/events.sass'
  @import '@/style/menu.sass'

  a
    img
      border: 2px solid $active
      width: 25%

  .error
    font-weight: bold
    font-family: $font
    color: #EF5350
    text-align: center
    padding: 12px
    box-sizing: border-box

  td > div

    padding-bottom: 20px

    > span.subdate
      font-size: 8pt
      display: block
    > i
      font-size: 9pt
      display: block
      color: $active
      font-variant: normal
      font-style: normal
    &:hover
      > i
        color: white
@media(max-width: 500px)
  a
    img
      width: 100%

</style>
