<template>
  <div class="calNav" v-if="Object.keys($store.state.multiCalendar).length">
    <a href="#" :class="{'active': $store.state.data.ID == id}" @click.prevent="changeCalendar(id)" v-for="(calendar, id) in $store.state.multiCalendar" :key="id">{{ calendar.title }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {
    changeCalendar (id) {
      var data = JSON.parse(JSON.stringify(this.$store.state.multiCalendar[id]))
      data.ID = id
      this.$parent.$parent.renderCalendar({data: data, settings: {
        save: false,
        callback: {},
        date: "",
        currentService: {},
        approvedDates: {},
        saveFormData: {},
        currentEvent: {},
        info: {
          click: 0
        }
      }})
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'

  .calNav
    display: flex
    margin-bottom: 20px
    align-items: center
    flex-direction: row
    a
      font-family: $font
      font-weight: bold
      flex: 1
      text-align: center
      margin-right: 10px
      padding: 5px
      border: 1px solid lightgrey
      color: black
      text-decoration: none
      &:last-of-type
        margin-right: 0
      &.active
        background-color: $active
        color: white
</style>
