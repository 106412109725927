<template>
  <div>

    <div class="menu single"  v-if="$store.getters.currentService">

      <a  href="#" @click.prevent="function(){ callback(false) }" :title="$store.getters.l.backToCalendar">{{ $store.getters.l.change }}</a>

      <a href="#" @click.prevent="function(){ callback(false) }" class="info">{{ $store.getters.l.backToCalendar }}</a>
    </div>


    <serviceInfo />

    <transition name="getDates" :key="offset">
    <table cellspacing="0" cellpadding="0" >


        <tbody v-for="(date, index) in get_dates" :key="index">


          <tr><td class="date" colspan="4">
            <span>
              {{ getWeekday(index) }}, {{ $getNullsBefore(getStringToDate(index).getDate()) }}. {{ $store.getters.l.monthsNames[getStringToDate(index).getMonth()+1] }} {{ getStringToDate(index).getFullYear() }}
            </span>
          </td></tr>

          <tr v-for="i in Array(( Math.ceil(date.length / 4))).keys()" :key="'row'+i+1">


            <td v-for="j in Array(4).keys()" :key="i + i + i + i + j + 2">
              <div
              v-on:click="getClick(index, date[i + i + i + i + j])"
              :style="$getStyling(['border-bottom-color'], 'accent')"
              @mouseenter="getHover"
              @mouseleave="removeHover"
              v-if="date[i + i + i + i + j]"
              v-bind:class="{
                active: true
              }"
              >
                  {{  date[i + i + i + i + j]  }}:__ <span>{{ $store.getters.l.oClock }}</span>
              </div>
            </td>
          </tr>

      </tbody>

    </table>
  </transition>

  <div>
    <a href="#" class="more" @click.prevent="offset = offset+1">{{ $store.getters.l.showMoreAppointments }}</a>
  </div>

  </div>
</template>

<script>

  import serviceInfo from '@/components/functions/serviceInfo';

  export default{

    components: { serviceInfo },
    props: {date: String, callback: Function},

    data(){

      return {
        offset: 1
      };

    },

    computed:{

      get_dates(){

        //var day = date.getDate();

        var r = {};

        for(var i = 0; i < (this.offset * 10); i++ ){

          var date = new Date();
          date.setDate( date.getDate() + i );

          r[date.getFullYear()+'-'+this.$getNullsBefore((date.getMonth()+1))+'-'+this.$getNullsBefore(date.getDate())] = [];

          for(var j = 0; j< 24; j++){

            var insert = new Date(date.getFullYear(), (date.getMonth()), date.getDate(), j, 0,0);

            if( this.is_active( insert ) )
              r[date.getFullYear()+'-'+this.$getNullsBefore((date.getMonth()+1))+'-'+this.$getNullsBefore(date.getDate())].push(this.$getNullsBefore(j.toString()));

          }

          if( !r[date.getFullYear()+'-'+this.$getNullsBefore((date.getMonth()+1))+'-'+this.$getNullsBefore(date.getDate())].length )
            delete r[date.getFullYear()+'-'+this.$getNullsBefore((date.getMonth()+1))+'-'+this.$getNullsBefore(date.getDate())];

        }

        return r;

      },



    },

    methods:{
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      getClick(day,hour){

        day = this.getStringToDate(day);
        day.setHours(hour);

        this.$store.commit('setHour', day);
        this.$store.commit('setView', 1);

      },

      getStringToDate(index){

        index = index.split(" ");
        index = index[0].split("-");

        return new Date(index[0], index[1]-1, index[2]);
      },

      getWeekday(index){

        var weekday = this.getStringToDate(index);
        weekday = weekday.getDay();

        if(weekday == 0)
          weekday = 7;

        return this.$store.getters.l.weekdays[weekday -1];
      },

      is_active(date){


        var begin = new Date(date.valueOf());
        var end = this.$getEndDate(begin);
        // var interval = this.$getNextDate(date, this.$store.getters.data)

        for(var k = 0; k<1000; k++){

          var check = new Date(date.valueOf())
          check.setMinutes((( k * 15)))


          if(check.getTime() > end.getTime()){
            break;
          }

          if( this.$checkDate(check.getFullYear()+'-'+this.$getNullsBefore((check.getMonth()+1))+'-'+this.$getNullsBefore(check.getDate())+" "+this.$getNullsBefore(check.getHours())+":"+this.$getNullsBefore(check.getMinutes())) == false)
            return false

        }
        return true

      }

    },



  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    td > div

      > span
        font-size: 8pt
        display: block

    a.back, .more
      color: #000
      font-size: 10pt
      font-family: $font

    .date, .more
      font-weight: bold
      font-size: 16pt
      padding-top: 20px
      padding-bottom: 10px
      border-bottom: 1px solid $standard

    .more
      margin-top: 10px
      display: block
      text-align: center

  .app td.date
    text-align: left
    span
      display: block
      padding: 30px 0 5px 0

  </style>
