<template>
  <div>

    <div class="menu">
      <a href="#" v-html="'<'" v-on:click.prevent="setYear(-1)"></a>
      <a href="#" v-on:click.prevent v-html="currYear+'-'+(currYear+9)"></a>
      <a href="#" v-html="'>'" v-on:click.prevent="setYear(1)"></a>
    </div>

    <transition name="getDates">
      <div class="dates" :key="currYear" v-if="active_count">
        <a v-for="(date, index) in data" :key="index"
        href="#"
        @mouseenter="getHover"
        @mouseleave="removeHover"
        class="active"
        v-on:click.prevent="setClick(date.i * 1, date.j * 1)"
        :title="date.title"
        :class="{
          current: date.currentYear
        }"
        :style="getStyling()"
        >{{ date.year }}</a>
      </div>
      <bookingNotPossible v-else />
    </transition>

    <changeTime />
    <debugTime />
  </div>
</template>

<script>

  import changeTime from '@/components/functions/changeTime';
  import debugTime from '@/components/functions/debugTime';
  import bookingNotPossible from '@/components/bookingNotPossible';

  export default{

    props: {date: String},
    components: {changeTime, debugTime, bookingNotPossible},

    data(){

      var date = this.date.split("-");

      var startYear = date[0] * 1+"";
      startYear = startYear[0]+""+startYear[1]+""+startYear[2]+"0";

      return{
        rows: Object.keys([1]),
        columns: Object.keys([1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]),
        currYear: startYear * 1,
        data: [],
        active_count: 0
      }
    },
    watch: {
      currYear () { this.data = []; this.getData() }
    },
    methods:{
      getData () {
        this.data = []
        this.active_count = 0
        this.rows.forEach(function (i) {
          this.columns.forEach(function (j) {
            window.console.log(this.currYear * 1 + i * 1 + i * 1 + i * 1 + j * 1)
            if (this.isActive(i * 1, j * 1)) {
              var cj = false
              if ((this.currYear * 1 + i * 1 + i * 1 + i * 1 + j * 1) == new Date().getFullYear()){
                cj = true
              }
              this.data.push({
                currentYear: cj,
                year: this.currYear * 1 + i * 1 + i * 1 + i * 1 + j * 1,
                i: i,
                j: j
              })
              this.active_count += 1
            }
          }.bind(this))
        }.bind(this))
      },
      getStyling () {
        return this.$getStyling(['border-bottom-color'], 'accent')
      },
      getHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')

        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = bg['background-color']
        }
      },
      removeHover (event) {
        var bg = this.$getStyling(['background-color'], 'accent')
        if (bg !== false && event.target.classList.contains('active')) {
          event.target.style['background-color'] = 'inherit'
        }
      },
      setClick(i,j){

        if(!this.isActive(i * 1, j * 1)){
          this.$store.commit('setErrorKey', new Date(this.currYear * 1 + i * 1 + i * 1 + i * 1 + j * 1, 0, 1, 0, 0));
          return false;
        }

        this.$store.commit('setYear', (this.currYear + i * 1 + i * 1 + i * 1 + j * 1));
        this.$store.commit('setView', 1);

      },
      isActive(i,j){

        return this.$checkDate(this.currYear + i + i + i + j);

      },
      setYear(add){
        this.currYear = (this.currYear * 1) + (add * 10);
      }
    },
    mounted () {
      this.getData()
    }

  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    .menu
      a
        &:nth-child(2)
          text-decoration: none
          cursor: default

  </style>
