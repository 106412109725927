<template>
  <div>
  
    <component :is="get_component()" :date="get_date()" :callback="function(ret){ setDate(ret) }" />

  </div>
</template>

<script>

import list from "@/components/view/services/list.vue";
import row from '@/components/view/list/row.vue';


  export default{
    components: {list, row},

    props: {date: String},


    methods:{

      get_date(){

        this.$store.commit("setDay", new Date(this.date.split(" ")[0]) );
        return this.$store.getters.date;
      },

      setDate(ret){

        this.$store.commit("setService", ret);


      },

      get_component(){

        if( !Object.keys(this.$store.getters.currentService).length )
          return 'list';

        return 'row';
      }

    }


  }

</script>

  <style lang="sass" scoped>

    @import '@/style/variables.sass'
    @import '@/style/year.sass'
    @import '@/style/menu.sass'

    td > div

      > span
        font-size: 8pt
        display: block

    .date
      text-align: center
      font-weight: bold
      font-size: 16pt
      padding-top: 20px
      padding-bottom: 10px
      border-bottom: 1px solid $standard



  </style>
