<template>
  <div>
    <transition name="getView">
      <info v-if="open" :key="$store.getters.date + $store.state.currentView + JSON.stringify($store.getters.currentService)">
        <div>
          <calendarTitle />
          <component v-bind:is="$store.getters.currentView"  :date="$store.getters.date" v-if="$store.getters.date" />
          <calendarLegend />
        </div>
      </info>
      <div v-if="!open" class="startBtn">
        <calendarTitle />
        <a :style="$getStyling(['background-color'], 'accent')" href="#" @click.prevent="setOpen">{{ btn }}</a>
      </div>
    </transition>
  </div>
</template>

<script>

import calendarTitle from '@/components/functions/showTitle'
import calendarLegend from '@/components/legend'
import info from '@/components/info'

export default {
  name: 'datepicker',

  components: { calendarTitle, info, calendarLegend },
  data () {
    return {
      open: false,
      btn: this.$store.getters.l.datepickerStartBtn
    }
  },
  methods: {
    setOpen () {
      this.getStart()
      this.open = true
      this.$store.commit("setDatpickerStartBtn")
    },
    getStart () {
      var dateStart = new Date()

      if (this.$store.getters.data.start){
        var date = this.$store.getters.data.start
        date = date.split(" ")
        date[0] = date[0].split("-")
        date[1] = date[1].split(":")
        date = new Date(date[0][0], (date[0][1] -1), date[0][2], date[1][0], date[1][1], 0)
      }

      if(typeof date != 'undefined' && dateStart.getTime() < date.getTime()){
        dateStart = date
      }

      this.$store.commit("setDate", dateStart);
    }
  },
  mounted(){

    if (this.$store.getters.datepickerStartBtn) {
      this.setOpen()
    }

    if (this.$store.getters.data.startBtn) {
      this.btn = this.$store.getters.data.startBtn
    }
  }

}
</script>
<style lang="sass" scoped="true" >
  @import '@/style/variables.sass'
  .startBtn
    text-align: center
    a
      color: white
      display: inline-block
      text-align: center
      padding: 7px 12px
      font-family: $font
      text-decoration: none
      font-size: 14pt
      background-color: $active
</style>
