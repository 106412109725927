<template>
  <div class="location" v-if="$store.getters.data.location">
    {{ $store.getters.data.location }}
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/style/variables.sass'
  @import '@/style/form/private.sass'
</style>
