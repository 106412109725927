import datePickerRules from './calculate_events.js'
import eventPickerRules from './eventpicker_events.js'


export default {

  install (Vue) {
    Vue.prototype.$getStyling = function (a, b) {
      if (typeof this.$store.getters.data.styling === 'object') {
        var ret = {}
        a.forEach(function (v) {
          ret[v] = this.$store.getters.data.styling[b]
        }.bind(this))

        return ret
      }
      return false
    }
    Vue.prototype.$getNextDate = function(day, data){
      var weekday = day.getDay();

      if( weekday == 0 )
        weekday = 7;

      weekday = weekday - 1;

      if(typeof data.next_appointments === 'undefined' || typeof data.next_appointments[weekday] === 'undefined') {
        return 15
      }
      if(isNaN(parseInt(data.next_appointments[weekday]))){
        return 15
      }

      return data.next_appointments[weekday] * 1
    }
    Vue.prototype.$generateDate = function(dateString){

      dateString = dateString.toString();

      dateString = dateString.split(" ");

      var Ymd = dateString[0].split( "-" );
      var His = [0, 0, 0];

      if(typeof dateString[1] == "string")
        His = dateString[1].split(":");

      return new Date(Ymd[0], (Ymd[1] * 1 - 1), Ymd[2], His[0], His[1], 0);
    }

    Vue.prototype.$cleanDates = function(){

      var r = new datePickerRules(this.$store.getters.data).cleanApprovedDates();
      return r;
    }

    Vue.prototype.$checkDate = function(date, end){
      window.debugBookingCalendar = []

      var r = new datePickerRules(this.$store.getters.data);
      r.setAppointmentEnd = end;

      var checkDate = r.getCheckDate(date);

      if (typeof r.approvedApplicants === 'object') {
        this.$store.commit("approvedApplicants", r.approvedApplicants);
      }

      return checkDate
    }

    Vue.prototype.$eventpickerDate = function(date){
      window.debugBookingCalendar = []

      var r = new eventPickerRules(this.$store.getters.data).getCheckDate(date);

      return r;
    }

    Vue.prototype.$getNullsBefore = function (nbr) {

      if(nbr *1 >= 10)
        return nbr;

        return 0+""+nbr;
    }

    Vue.prototype.$getServiceTime = function (time) {

      if (time >= 60) {
        time = (time / 60)
      }

      if (time === 1) {
        return time + ' ' + this.$store.getters.l.hour
      }
      if (time < 15) {
        return time + ' ' + this.$store.getters.l.hours
      }

      if (time < 60) {
        return time + ' ' + this.$store.getters.l.minutes
      }
    }

    Vue.prototype.$getEndDate = function (date, data, service) {

      if( typeof data == "undefined" )
        data = this.$store.getters.data;

      if( typeof service == "undefined" )
        service = this.$store.getters.currentService;

      var weekday = date.getDay();
      var endTime = "";
      var end = "";

      if( weekday == 0 )
        weekday = 7;

      weekday = weekday - 1;

      if( typeof data.include.weekdays == "undefined" || typeof data.include.weekdays[weekday] == "undefined")
        return date;

      if( typeof service == "object" ){
        if( service.time < 15 ) {
          endTime = service.time * 60;
        } else {
          endTime = service.time * 1;
        }
      }

      if( !endTime && isNaN(parseInt(data.include.weekdays[weekday][2])) )
        return date;

      if( !endTime )
        endTime = parseInt(data.include.weekdays[weekday][2]);

      end = new Date( date.valueOf() );
      end.setMinutes( end.getMinutes() * 1 + endTime );

      return end;

    };

    Vue.prototype.$nl2br = function (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    }


  }
}
