<template>
  <div class="app" v-bind:key="JSON.stringify($store.getters.data)">

    <live :getReload="function () { getReload() }" v-if="Object.keys($store.getters.data).length" />
    <div v-else class="loading">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
      {{ $store.getters.l.loading }}
    </div>

  </div>

</template>

<script>

import live from '@/components/live';

export default{

  components: { live },
  props: { data: String, services: String, settings: String, callback: String },

  data(){

    return{
      productiveData: false
    }
  },

  mounted(){
    this.getLoad()
  },

  methods:{
    getReload () {
      this.$store.commit("setSettings", {'currentView': 0});
      if (this.$store.getters.viewBox === 'calendar') {
        this.$store.commit("setSettings", {'currentView': 2});
      }
      this.$store.commit('setSave', false)
      this.$store.commit("setData", {data: {}})
      this.getLoad()
    },
    getLoad () {

      this.loadQS();

      this.getData(function(data){
        if (typeof data.data.calendar === 'object'){
          this.$store.commit('setCalendars', data.data.calendar)
          data.data = Object.values(data.data.calendar)[0]
        }
        this.renderCalendar(data)

      }.bind(this));
    },
    renderCalendar (data) {
      this.$store.commit("setData", data);

      if(typeof data.data === 'object') {
        this.productiveData = data
      }
      this.calculateAlternativeListView();

      if(typeof data.services != "undefined")
        this.$store.commit("setServices", data.services);

      if(typeof this.productiveData.settings != "undefined")
        this.$store.commit("setSettings", this.productiveData.settings);
    },
    calculateAlternativeListView(){

      if( !this.productiveData || typeof this.productiveData.data === 'undefined' || typeof this.productiveData.data.include === 'undefined' || this.productiveData.data.type !== "datepicker" )
        return false;

      var check;
      var error = false;

      Object.values(this.productiveData.data.include.weekdays).forEach(function(v){


        if( typeof v == "object" && typeof v[2] != "undefined"){

          if(typeof check == "undefined")
            check = v[2];


          if( !isNaN(parseInt(v[2])) ){
            error = true;
            return false;
          }

          if( v[2] != check ){
            error = true;
            return false;
          }



        }

      });

      if(!error)
        this.$store.commit("setSettings", {'currentView': 0, 'viewBox': 'list'});

    },

    getData(callback){

      var raw = JSON.parse(atob(this.data));

      if( typeof raw.load != "undefined" ){

        this.$store.getters.callback.getCurlData(raw.load, function(data){ this.productiveData = Object.assign(raw, data); callback(data); }.bind(this));

        return false;
      }

      return callback(raw);

    },

    loadQS(){

      if(typeof this.callback == "undefined" || !this.callback)
        return false;

      var callback = "";
      eval( "callback = "+this.callback );

      callback = new callback();
      var qs = require('qs');
      callback.setQs(qs);

      callback = this.setCallbackUrl(callback);

      this.$store.commit("setCallback", callback);
    },

    setCallbackUrl(callback){

      var data = JSON.parse(atob(this.data));

      if( typeof data.load != "object" )
        return callback;

      /*
      if( typeof data.load.demo == "boolean" && data.load.demo == true )
        callback.setUrl("http://devapi.terminflix.de/de/");

      else
        callback.setUrl("https://api.terminflix.de/de/");
      */

      if( typeof data.load.lang == "string" ){
        callback.setUrl( callback.url.replace("/de/", "/"+data.load.lang+"/") );
        this.$store.commit("setLanguage", data.load.lang);
      }

      return callback;
    }

  },



}

</script>

<style lang="sass" scoped>
  @import '@/style/variables.sass'
  .app
    width: 100%
    .loading
      font-family: $font
      color: $active
      text-align: center
      font-size: 16pt
      font-weight: bold
      .sk-cube-grid
        width: 60px
        height: 60px
        margin: 10px auto
      .sk-cube-grid .sk-cube
        width: 33%
        height: 33%
        background-color: $active
        float: left
        -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out
                animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out

      .sk-cube-grid .sk-cube1
        -webkit-animation-delay: 0.2s
                animation-delay: 0.2s
      .sk-cube-grid .sk-cube2
        -webkit-animation-delay: 0.3s
                animation-delay: 0.3s
      .sk-cube-grid .sk-cube3
        -webkit-animation-delay: 0.4s
                animation-delay: 0.4s
      .sk-cube-grid .sk-cube4
        -webkit-animation-delay: 0.1s
                animation-delay: 0.1s
      .sk-cube-grid .sk-cube5
        -webkit-animation-delay: 0.2s
                animation-delay: 0.2s
      .sk-cube-grid .sk-cube6
        -webkit-animation-delay: 0.3s
                animation-delay: 0.3s
      .sk-cube-grid .sk-cube7
        -webkit-animation-delay: 0s
                animation-delay: 0s
      .sk-cube-grid .sk-cube8
        -webkit-animation-delay: 0.1s
                animation-delay: 0.1s
      .sk-cube-grid .sk-cube9
        -webkit-animation-delay: 0.2s
                animation-delay: 0.2s

      @-webkit-keyframes sk-cubeGridScaleDelay
        0%, 70%, 100%
          -webkit-transform: scale3D(1, 1, 1)
                  transform: scale3D(1, 1, 1)
        35%
          -webkit-transform: scale3D(0, 0, 1)
                  transform: scale3D(0, 0, 1)

      @keyframes sk-cubeGridScaleDelay
        0%, 70%, 100%
          -webkit-transform: scale3D(1, 1, 1)
                  transform: scale3D(1, 1, 1)
        35%
          -webkit-transform: scale3D(0, 0, 1)
                  transform: scale3D(0, 0, 1)

</style>
