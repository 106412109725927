<template>

  <div>
    <component :is="get_component()" :date="$store.getters.date" :callback="function(service){ callback(service); }"/>
  </div>

</template>

<script>

import hours from "@/components/view/day/hours"
import list from "@/components/view/services/list"

export default{

  components: {list, hours},

  data(){
    return{
      service: ""
    }
  },

  methods:{

    get_component(){

      if(this.service)
        return "hours";

      return "list";

    },
    callback(service){
      this.service = service;

      this.$store.commit("setService", service);
    }
  }
}
</script>
